<template>
	
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">We use cookies</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body small">
			<p class="mb-0">We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience, personalise content, and analyse website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking 'Accept', you agree to our website's cookie use as described in our <a href="#" v-on:click="$emit('update:tab', 'policy')">Cookie Policy</a>. You can change your cookie settings at any time by clicking “Preferences”.</p>
		</div>
		<div class="modal-footer justify-content-between">
			<button type="button" class="btn btn-link" v-on:click="$emit('update:tab', 'preferences')">Preferences</button>
			<div class="d-flex align-items-center">
				<button type="button" class="btn btn-outline-primary me-2">Decline</button>
				<button type="button" class="btn btn-primary">Accept</button>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: 'components.gdpr_consent',
	props: {
		site: Object
	},
	emits: [
		'update:tab',
	],
}
</script>

<style>

</style>

<template>
	
<div class="modal-content">
<div class="modal-header">
<h5 class="modal-title">Cookie policy</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body small">
<p>Last updated: [date]</p>
<p>This Cookie Policy explains how {{ site.settings['name'] }} ("Company," "we," "us," and "our") uses cookies and similar technologies to recognize you when you visit our website at {{ site.settings['url'] }} ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
<p>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>
<h3 class="h4">What are cookies?</h3>
<p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
<p>Cookies set by the website owner (in this case, {{ site.settings['name'] }}) are called "first-party cookies." Cookies set by parties other than the website owner are called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
<h3 class="h4">Why do we use cookies?</h3>
<p>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.</p>
<h3 class="h4">How can I control cookies?</h3>
<p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>
<p>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies.</p>
<p>The specific types of first- and third-party cookies served through our Website and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Online Properties you visit):</p>
<div class="accordion mb-4" id="cookie-list">
<div class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-essential" aria-expanded="true" aria-controls="cookie-list-essential"> Essential cookies </button>
</h3>
<div id="cookie-list-essential" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>wp-settingswordpress_[hash]</td>
<td>to store the authentication details on login. The authentication details include the username and double hashed copy of the password. However, this usage of the cookie is limited to the admin console area, the backend dashboard of the website.</td>
<td>{{ site.settings['name'] }}</td>
<td>When logged out</td>
</tr>
<tr>
<td>wordpress_logged_in_[hash]</td>
<td>to indicate when you are logged in, and who you are. This cookie is maintained on the front-end of the website as well when logged in.</td>
<td>{{ site.settings['name'] }}</td>
<td>When logged out</td>
</tr>
<tr>
<td>wordpress_test_cookie</td>
<td>to check if the cookies are enabled on the browser to provide appropriate user experience to the users. This cookie is used on the front-end, even if you are not logged in.</td>
<td>{{ site.settings['name'] }}</td>
<td>When logged out</td>
</tr>
<tr>
<td>wp-settings-{time}-[UID]</td>
<td>to customize the view of your admin interface and the front-end of the website. The value represented by [UID] is the individual user ID of the user as given to them in the users’ database table.</td>
<td>{{ site.settings['name'] }}</td>
<td>When logged out</td>
</tr>
<tr>
<td>shown_cookies</td>
<td>Used to check if the cookie permissions modal has previously been displayed.</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>APISID</td>
<td>Used by Google to store user preferences and information on Google maps.</td>
<td>{{ site.settings['name'] }}</td>
<td>2 years</td>
</tr>
<tr>
<td>SID</td>
<td>Security cookie to confirm visitor authenticity, prevent fraudulent use of login data and protect visitor data from unauthorized access.</td>
<td>{{ site.settings['name'] }}</td>
<td>2 years</td>
</tr>
<tr>
<td>SSID</td>
<td>Google collects visitor information for videos hosted by YouTube on maps integrated with Google Maps.</td>
<td>{{ site.settings['name'] }}</td>
<td>Persistent</td>
</tr>
<tr>
<td>HSID</td>
<td>Security cookie to confirm visitor authenticity, prevent fraudulent use of login data and protect user data from unauthorized access.</td>
<td>{{ site.settings['name'] }}</td>
<td>2 years</td>
</tr>
<tr>
<td>SAPISID</td>
<td>Google collects visitor information for videos hosted by YouTube.</td>
<td>{{ site.settings['name'] }}</td>
<td>Persistent</td>
</tr>
<tr>
<td>shown_cookies</td>
<td>Used to check if the cookie permissions modal has previously been displayed.</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_google-analytics</td>
<td>Used to check whether google_analytics cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_google-ads</td>
<td>Used to check whether google_ads cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_hubspot</td>
<td>Used to check whether hubspot cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_linkedin</td>
<td>Used to check whether linkedin cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_meta</td>
<td>Used to check whether meta cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_hotjar</td>
<td>Used to check whether hotjar cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_microsoft</td>
<td>Used to check whether microsoft cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_clarity</td>
<td>Used to check whether clarity cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_tag-manager</td>
<td>Used to check whether tag_manager cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
<tr>
<td>accept_cookies_other</td>
<td>Used to check whether other cookies have the correct permissions to be set</td>
<td>{{ site.settings['name'] }}</td>
<td>Local Storage</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-google-analytics-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-google-analytics" aria-expanded="true" aria-controls="cookie-list-google-analytics"> Google Analytics cookies </button>
</h3>
<div id="cookie-list-google-analytics" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate Google Analytics. You can learn more about how Google manages cookies and your data here <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" style="line-break: anywhere;">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>__utma</td>
<td>This is one of the four main cookies set by the Google Analytics service which enables website owners to track visitor behaviour and measure site performance. It it used to calculate new and returning visitor statistics. The cookie is updated every time data is sent to Google Analytics.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>__utmv</td>
<td>Used to store visitor-level custom variable data. This cookie is created when a developer uses the _setCustomVar method with a visitor level custom variable. This cookie was also used for the deprecated _setVar method. The cookie is updated every time data is sent to Google Analytics.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>__utmz</td>
<td>Stores the traffic source or campaign that explains how you reached the Websites. The cookie is created when the javascript library executes and is updated every time data is sent to Google Analytics.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>_ga</td>
<td>cookie used to distinguish individual users on your domain.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>_ga_[property-id]</td>
<td>cookie containing a unique ID that makes Google Analytics work.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>_gid</td>
<td>cookie used to distinguish individual users on your domain</td>
<td>Google</td>
<td>24 hours</td>
</tr>
<tr>
<td>_gat</td>
<td>cookie used to limit amount of user requests in order to maintain your website’s performance.</td>
<td>Google</td>
<td>1 minute</td>
</tr>
<tr>
<td>AMP_TOKEN</td>
<td>cookie containing a unique ID assigned to each user on your domain.</td>
<td>Google</td>
<td>1 year</td>
</tr>
<tr>
<td>_gac_[property-id]</td>
<td>cookie containing a unique ID that makes Google Analytics and Ads work together.</td>
<td>Google</td>
<td>90 days</td>
</tr>
<tr>
<td>_gat_gtag_UA_[property-id]</td>
<td>cookie containing a unique ID that makes Google Analytics work.</td>
<td>Google</td>
<td>1 minute</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-google-ads-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-google-ads" aria-expanded="true" aria-controls="cookie-list-google-ads"> Google Ads cookies </button>
</h3>
<div id="cookie-list-google-ads" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate Google Ads. You can learn more about how Google manages cookies and your data here <a href="https://policies.google.com/privacy" target="_blank" style="line-break: anywhere;">https://policies.google.com/privacy</a> and here <a href="https://support.google.com/admanager/answer/2839090?hl=en" target="_blank" style="line-break: anywhere;">https://support.google.com/admanager/answer/2839090?hl=en</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>test_cookie</td>
<td>Used by Google DoubleClick to check if the user’s browser supports cookies.</td>
<td>Double Click</td>
<td>Session</td>
</tr>
<tr>
<td>IDE</td>
<td>Used by Google DoubleClick to register and report the website user’s actions after viewing or clicking one of the advertiser’s ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</td>
<td>Double Click</td>
<td>1 year</td>
</tr>
<tr>
<td>__Secure-3PAPISID</td>
<td>Builds a profile of website visitor interests to show relevant and personalized ads through retargeting.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>__Secure-3PSID</td>
<td>Builds a profile of website visitor interests to show relevant and personalized ads through retargeting.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>__Secure-3PSIDCC</td>
<td>Builds a profile of website visitor interests to show relevant and personalized ads through retargeting.</td>
<td>Google</td>
<td>2 years</td>
</tr>
<tr>
<td>ANID</td>
<td>Lists ads on Google sites based on recent searches.</td>
<td>Google</td>
<td>Persistent</td>
</tr>
<tr>
<td>NID</td>
<td>Stores visitors’ preferences and personalizes ads on Google websites based on recent searches and interactions.</td>
<td>Google</td>
<td>6 months</td>
</tr>
<tr>
<td>1P_JAR</td>
<td>Based on recent searches and previous interactions, custom ads are shown on Google sites.</td>
<td>Google</td>
<td>7 days</td>
</tr>
<tr>
<td>CONSENT</td>
<td>Stores visitors’ preferences and personalizes ads.</td>
<td>Google</td>
<td>Persistent</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-hubspot-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-hubspot" aria-expanded="true" aria-controls="cookie-list-hubspot"> HubSpot cookies </button>
</h3>
<div id="cookie-list-hubspot" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate HubSpot. You can learn more about how HubSpot manages cookies and your data here <a href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser" target="_blank" style="line-break: anywhere;">https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>__hs_opt_out</td>
<td>This cookie is used by the opt-in privacy policy to remember not to ask the visitor to accept cookies again.</td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>__hs_do_not_track</td>
<td>This cookie can be set to prevent the tracking code from sending any information to HubSpot.</td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>__hs_initial_opt_in</td>
<td>This cookie is used to prevent the banner from always displaying when visitors are browsing in strict mode.</td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>__hs_cookie_cat_pref</td>
<td>This cookie is used to record the categories a visitor consented to.</td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>__hs_gpc_banner_dismiss</td>
<td>This cookie is used when the Global Privacy Control banner is dismissed.</td>
<td>HubSpot</td>
<td>180 days</td>
</tr>
<tr>
<td>hs_ab_test</td>
<td>This cookie is used to consistently serve visitors the same version of an A/B test page they’ve seen before.</td>
<td>HubSpot</td>
<td>End of session</td>
</tr>
<tr>
<td>&lt;id&gt;_key</td>
<td>When visiting a password-protected page, this cookie is set so future visits to the page from the same browser do not require login again.</td>
<td>HubSpot</td>
<td>30 minutes</td>
</tr>
<tr>
<td>hs-messages-is-open</td>
<td>This cookie is used to determine and save whether the chat widget is open for future visits.</td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>hs-messages-hide-welcome-message</td>
<td>This cookie is used to prevent the chat widget welcome message from appearing again for one day after it is dismissed.</td>
<td>HubSpot</td>
<td>1 day</td>
</tr>
<tr>
<td>__hsmem</td>
<td>This cookie is set when visitors log in to a HubSpot-hosted site.</td>
<td>HubSpot</td>
<td>7 days</td>
</tr>
<tr>
<td>hs-membership-csrf</td>
<td>This cookie is used to ensure that content membership logins cannot be forged.</td>
<td>HubSpot</td>
<td>End of session</td>
</tr>
<tr>
<td>hs_langswitcher_choice</td>
<td>This cookie is used to save a visitor’s selected language choice when viewing pages in multiple languages.</td>
<td>HubSpot</td>
<td>2 years</td>
</tr>
<tr>
<td>__cfruid</td>
<td>This cookie is set by HubSpot’s CDN provider because of their rate limiting policies. It expires at the end of the session. Learn more about Cloudflare cookies. </td>
<td>HubSpot</td>
<td>End of session</td>
</tr>
<tr>
<td>__cf_bm</td>
<td>This cookie is set by HubSpot's CDN provider and is a necessary cookie for bot protection. It expires in 30 minutes. Learn more about Cloudflare cookies. </td>
<td>HubSpot</td>
<td>30 minutes</td>
</tr>
<tr>
<td>__hstc</td>
<td>The main cookie for tracking visitors. </td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>hubspotutk</td>
<td>This cookie keeps track of a visitor's identity. It is passed to HubSpot on form submission and used when deduplicating contacts.</td>
<td>HubSpot</td>
<td>6 months</td>
</tr>
<tr>
<td>__hssc</td>
<td>This cookie keeps track of sessions. This is used to determine if HubSpot should increment the session number and timestamps in the __hstc cookie.. </td>
<td>HubSpot</td>
<td>30 minutes</td>
</tr>
<tr>
<td>__hssrc</td>
<td>Whenever HubSpot changes the session cookie, this cookie is also set to determine if the visitor has restarted their browser.</td>
<td>HubSpot</td>
<td>End of session</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-linkedin-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-linkedin" aria-expanded="true" aria-controls="cookie-list-linkedin"> LinkedIn cookies </button>
</h3>
<div id="cookie-list-linkedin" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate LinkedIn. You can learn more about how LinkedIn manages cookies and your data here <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" style="line-break: anywhere;">https://www.linkedin.com/legal/privacy-policy</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>bcookie </td>
<td>Browser Identifier cookie to uniquely indentify devices accessing LinkedIn to detect abust on the platform and diagnostic purposes</td>
<td>LinkedIn</td>
<td>1 year</td>
</tr>
<tr>
<td>bscookie</td>
<td>Used for remembering that a logged in user is verified by two factor authentication and has previously logged in.</td>
<td>LinkedIn</td>
<td>1 year</td>
</tr>
<tr>
<td>JSESSIONID</td>
<td>Used for Cross Site Request Forgery (CSRF) protection and URL signature validation.</td>
<td>LinkedIn</td>
<td>Session</td>
</tr>
<tr>
<td>lang</td>
<td>Used to remember a user's language setting to ensure LinkedIn.com displays in the language selected by the user in their settings.</td>
<td>LinkedIn</td>
<td>Session</td>
</tr>
<tr>
<td>lidc</td>
<td>To facilitate data center selection.</td>
<td>LinkedIn</td>
<td>24 hours</td>
</tr>
<tr>
<td>sdsc</td>
<td>Signed data service context cookie used for database routing to ensure consistency across all databases when a change is made. Used to ensure that user-inputted content is immediately available to the submitting user upon submission.</td>
<td>LinkedIn</td>
<td>Session</td>
</tr>
<tr>
<td>li_gc</td>
<td>Used to store consent of guests regarding the use of cookies for non-essential purposes.</td>
<td>LinkedIn</td>
<td>6 months</td>
</tr>
<tr>
<td>li_mc</td>
<td>Used as a temporary cache to avoid database lookups for a member's consent for use of non-essential cookies and used for having consent information on the client side to enforce consent on the client side.</td>
<td>LinkedIn</td>
<td>6 months</td>
</tr>
<tr>
<td>UserMatchHistory</td>
<td>MLinkedIn Ads ID syncing</td>
<td>LinkedIn</td>
<td>30 days</td>
</tr>
<tr>
<td>AnalyticsSyncHistory</td>
<td>Used to store information about the time a sync took place with the lms_analytics cookie</td>
<td>LinkedIn</td>
<td>30 days</td>
</tr>
<tr>
<td>lms_ads</td>
<td>Used to identify LinkedIn Members off LinkedIn for analytics</td>
<td>LinkedIn</td>
<td>30 days</td>
</tr>
<tr>
<td>li_fat_id</td>
<td>Member indirect identifier for Members for conversion tracking, retargeting, analytics</td>
<td>LinkedIn</td>
<td>30 days</td>
</tr>
<tr>
<td>li_sugr</td>
<td>Used to make a probabilistic match of a user's identity outside the Designated Countries</td>
<td>LinkedIn</td>
<td>90 days</td>
</tr>
<tr>
<td>_guid</td>
<td>Used to identify a LinkedIn Member for advertising through Google Ads</td>
<td>LinkedIn</td>
<td>90 days</td>
</tr>
<tr>
<td>BizographicsOptOut</td>
<td>Used to determine opt-out status for non-members</td>
<td>LinkedIn</td>
<td>10 years</td>
</tr>
<tr>
<td>li_giant</td>
<td>Indirect indentifier for groups of LinkedIn Members used for conversion tracking</td>
<td>LinkedIn</td>
<td>7 days</td>
</tr>
<tr>
<td>oribi_cookie_test</td>
<td>To determine if tracking can be enabled on current domain</td>
<td>Oribi</td>
<td>Session</td>
</tr>
<tr>
<td>oribili_user_guid </td>
<td>Used to count unique visitors to a website</td>
<td>Oribi</td>
<td>1 day</td>
</tr>
<tr>
<td>ln_or </td>
<td>Used to determine if Oribi analytics can be carried out on a specific domain</td>
<td>Oribi</td>
<td>1 day</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-meta-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-meta" aria-expanded="true" aria-controls="cookie-list-meta"> Meta cookies </button>
</h3>
<div id="cookie-list-meta" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate Meta. You can learn more about how Meta manages cookies and your data here <a href="https://en-gb.facebook.com/privacy/policy/" target="_blank" style="line-break: anywhere;">https://en-gb.facebook.com/privacy/policy/</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>_fbp</td>
<td>is used to distinguish and keep track of your unique users.</td>
<td>Meta</td>
<td>90 days</td>
</tr>
<tr>
<td>_fbc</td>
<td>is only set when a user arrives at your website from an Ad, and the destination URL includes the click identifier "fbclid".</td>
<td>Meta</td>
<td>90 days</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-hotjar-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-hotjar" aria-expanded="true" aria-controls="cookie-list-hotjar"> HotJar cookies </button>
</h3>
<div id="cookie-list-hotjar" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate HotJar. You can learn more about how HotJar manages cookies and your data here <a href="https://www.hotjar.com/privacy/" target="_blank" style="line-break: anywhere;">https://www.hotjar.com/privacy/</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>_hjSessionUser_{site_id}</td>
<td>Set when a user first lands on a page. Persists the Hotjar User ID which is unique to that site. Hotjar does not track users across different sites. Ensures data from subsequent visits to the same site are attributed to the same user ID.</td>
<td>HotJar</td>
<td>365 days</td>
</tr>
<tr>
<td>_hjid</td>
<td>This is an old cookie that we do not set anymore, but if a user has it unexpired in their browser, we will reuse its value and migrate to _hjSessionUser_{site_id}. Set when a user first lands on a page. Persists the Hotjar User ID which is unique to that site. Ensures data from subsequent visits to the same site are attributed to the same user ID.</td>
<td>HotJar</td>
<td>365 days</td>
</tr>
<tr>
<td>_hjFirstSeen</td>
<td>Identifies a new user’s first session. Used by Recording filters to identify new user sessions.</td>
<td>HotJar</td>
<td>30 minutes</td>
</tr>
<tr>
<td>_hjHasCachedUserAttributes</td>
<td>Enables us to know whether the data set in _hjUserAttributes Local Storage item is up to date or not.</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>_hjUserAttributesHash</td>
<td>Enables us to know when any User Attribute has changed and needs to be updated.</td>
<td>HotJar</td>
<td>2 minutes</td>
</tr>
<tr>
<td>_hjUserAttributes</td>
<td>Stores User Attributes sent through the Hotjar Identify API.</td>
<td>HotJar</td>
<td>Local Storage</td>
</tr>
<tr>
<td>hjViewportId</td>
<td>Stores user viewport details such as size and dimensions.</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>hjActiveViewportIds</td>
<td>Stores user active viewports IDs. Stores an expirationTimestamp that is used to validate active viewports on script initialization.</td>
<td>HotJar</td>
<td>Local Storage</td>
</tr>
<tr>
<td>_hjSession_{site_id}</td>
<td>Holds current session data. Ensures subsequent requests in the session window are attributed to the same session.</td>
<td>HotJar</td>
<td>30 minutes</td>
</tr>
<tr>
<td>_hjSessionTooLarge</td>
<td>Causes Hotjar to stop collecting data if a session becomes too large. Determined automatically by a signal from the server if the session size exceeds the limit.</td>
<td>HotJar</td>
<td>1 hour</td>
</tr>
<tr>
<td>_hjSessionResumed</td>
<td>Set when a session/recording is reconnected to Hotjar servers after a break in connection.</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>_hjCookieTest</td>
<td>Checks to see if the Hotjar Tracking Code can use cookies. If it can, a value of 1 is set. Deleted almost immediately after it is created.</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>_hjLocalStorageTest</td>
<td>Checks if the Hotjar Tracking Code can use Local Storage. If it can, a value of 1 is set. Data stored in _hjLocalStorageTest has no expiration time, but it is deleted almost immediately after it is created.</td>
<td>HotJar</td>
<td>100ms</td>
</tr>
<tr>
<td>_hjSessionStorageTest</td>
<td>Checks if the Hotjar Tracking Code can use Session Storage. If it can, a value of 1 is set. Data stored in _hjSessionStorageTest has no expiration time, but it is deleted almost immediately after it is created.</td>
<td>HotJar</td>
<td>100ms</td>
</tr>
<tr>
<td>_hjIncludedInPageviewSample</td>
<td>Set to determine if a user is included in the data sampling defined by your site's pageview limit.</td>
<td>HotJar</td>
<td>2 minutes</td>
</tr>
<tr>
<td>_hjIncludedInSessionSample_{site_id}</td>
<td>Set to determine if a user is included in the data sampling defined by your site's daily session limit.</td>
<td>HotJar</td>
<td>2 minutes</td>
</tr>
<tr>
<td>_hjAbsoluteSessionInProgress</td>
<td>Used to detect the first pageview session of a user.</td>
<td>HotJar</td>
<td>30 minutes</td>
</tr>
<tr>
<td>_hjTLDTest</td>
<td>We try to store the _hjTLDTest cookie for different URL substring alternatives until it fails. Enables us to try to determine the most generic cookie path to use, instead of page hostname. It means that cookies can be shared across subdomains (where applicable). After this check, the cookie is removed.</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>_hjRecordingEnabled</td>
<td>Set when a Recording starts. Read when the Recording module is initialized to see if the user is already in a recording in a particular session.</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>_hjRecordingLastActivity</td>
<td>Updated when a user recording starts and when data is sent to the server (the user performs an action that Hotjar records).</td>
<td>HotJar</td>
<td>Session</td>
</tr>
<tr>
<td>_hjClosedSurveyInvites</td>
<td>Set when a user interacts with a Link Survey invitation modal. Ensures the same invite does not reappear if it has already been shown.</td>
<td>HotJar</td>
<td>365 days</td>
</tr>
<tr>
<td>_hjDonePolls</td>
<td>Set when a user completes an on-site Survey. Ensures the same Survey does not reappear if it has already been filled in.</td>
<td>HotJar</td>
<td>365 days</td>
</tr>
<tr>
<td>_hjMinimizedPolls</td>
<td>Set when a user minimizes an on-site Survey. Ensures that the Survey stays minimized when the user navigates through your site.</td>
<td>HotJar</td>
<td>365 days</td>
</tr>
<tr>
<td>_hjShownFeedbackMessage</td>
<td>...Set when a user minimizes or completes a Feedback widget. Ensures the Feedback widget will load as minimized if the user navigates to another page where it is set to show.</td>
<td>HotJar</td>
<td>1 day</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-microsoft-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-microsoft" aria-expanded="true" aria-controls="cookie-list-microsoft"> Microsoft cookies </button>
</h3>
<div id="cookie-list-microsoft" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>These cookies are used to integrate Bing Ads and analytics services. You can learn more about how Microsoft manages cookies and your data here <a href="https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security" target="_blank">https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>MR</td>
<td>Analytics cookie that's used to pass user action information to the Bing advertising network.</td>
<td>Bing Ads</td>
<td>180 days</td>
</tr>
<tr>
<td>MUID</td>
<td>Used widely by Microsoft as a unique user ID. This bing.com cookie enables tracking by synchronising the ID across many Microsoft domains.</td>
<td>Bing Ads</td>
<td>390 days</td>
</tr>
<tr>
<td>MMUIDB</td>
<td>This cookie registers data about you from multiple visits and on multiple websites. This information is used to measure the efficiency of advertisement on websites.</td>
<td>Bing Ads</td>
<td>390 days</td>
</tr>
<tr>
<td>_uetsid</td>
<td>Used by Microsoft Bing Ads and is a tracking cookie. It allows us to engage with a user that has previously visited our website.</td>
<td>Bing Ads</td>
<td>Session</td>
</tr>
<tr>
<td>_uetvid</td>
<td>Used by Microsoft Bing Ads and is a tracking cookie. It allows us to engage with a user that has previously visited our website.</td>
<td>Bing Ads</td>
<td>15 days</td>
</tr>
<tr>
<td>SRCHD</td>
<td>This cookie is an analytics service that connects data from the Bing advertising network with actions performed on the website.</td>
<td>Bing Ads</td>
<td>2 years</td>
</tr>
<tr>
<td>SRCHHPGUSR</td>
<td>This cookie is an analytics service that connects data from the Bing advertising network with actions performed on the website.</td>
<td>Bing Ads</td>
<td>2 years</td>
</tr>
<tr>
<td>SRCHUID</td>
<td>This cookie is an analytics service that connects data from the Bing advertising network with actions performed on the website.</td>
<td>Bing Ads</td>
<td>2 years</td>
</tr>
<tr>
<td>SRCHUSR</td>
<td>This cookie is an analytics service that connects data from the Bing advertising network with actions performed on the website.</td>
<td>Bing Ads</td>
<td>2 years</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div v-if="site.settings['analytics-clarity-id']" class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-clarity" aria-expanded="true" aria-controls="cookie-list-clarity"> Microsoft Clarity cookies </button>
</h3>
<div id="cookie-list-clarity" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>Clarity's normal functioning requires that they set cookies on your browser. These cookies are placed by a setup script we have installed to run Clarity. When the script is installed, Clarity's cookies send nonpersonally identifiable information about you. You can learn more about how Microsoft manages cookies and your data here <a href="https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security" target="_blank">https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security</a>
</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>_clck</td>
<td>Persists the Clarity User ID and preferences, unique to that site is attributed to the same user ID.</td>
<td>Microsoft Clarity</td>
<td>1 year</td>
</tr>
<tr>
<td>_clsk</td>
<td>Connects multiple page views by a user into a single Clarity session recording.</td>
<td>Microsoft Clarity</td>
<td>1 day</td>
</tr>
<tr>
<td>CLID</td>
<td>Identifies the first-time Clarity saw this user on any site using Clarity.</td>
<td>Microsoft Clarity</td>
<td>1 year</td>
</tr>
<tr>
<td>ANONCHK</td>
<td>Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0.</td>
<td>Microsoft Clarity</td>
<td>10 Mins</td>
</tr>
<tr>
<td>MR</td>
<td>Indicates whether to refresh MUID.</td>
<td>Microsoft Clarity</td>
<td>Session</td>
</tr>
<tr>
<td>MUID</td>
<td>Identifies unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes.</td>
<td>Microsoft Clarity</td>
<td>1 Year</td>
</tr>
<tr>
<td>SM</td>
<td>Used in synchronizing the MUID across Microsoft domains.</td>
<td>Bing Ads</td>
<td>Session</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
<div class="accordion-item">
<h3 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cookie-list-other" aria-expanded="true" aria-controls="cookie-list-other"> Other cookies </button>
</h3>
<div id="cookie-list-other" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#cookie-list">
<div class="accordion-body">
<p>This is a list of any other cookies that may be used. These are optional and not required for the site to function.</p>
<div class="table-responsive">
<table class="table mb-4">
<tbody>
<tr>
<th width="20%">Name</th>
<th>Purpose</th>
<th>Service</th>
<th>Expires</th>
</tr>
<tr>
<td>comment_author_[hash]</td>
<td>remembers the value entered into the comment form’s name field.</td>
<td>{{ site.settings['name'] }}</td>
<td>1 year</td>
</tr>
<tr>
<td>comment_author_email_[hash]</td>
<td>remembers the value entered into the comment form’s email field.</td>
<td>{{ site.settings['name'] }}</td>
<td>1 year</td>
</tr>
<tr>
<td>comment_author_url_[hash]</td>
<td>remembers the value entered into the comment form’s URL field.</td>
<td>{{ site.settings['name'] }}</td>
<td>1 year</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
<h3 class="h4">How can I control cookies on my browser?</h3>
<p>As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you should visit your browser's help menu for more information. The following is information about how to manage cookies on the most popular browsers:</p>
<p>
<a href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies" target="_blank">Chrome</a>, <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Internet Explorer</a>, <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US" target="_blank">Firefox</a>, <a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac" target="_blank">Safari</a>, <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Edge</a>, <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">Opera</a>
</p>
<p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit:</p>
<p>
<a href="https://optout.aboutads.info/?c=2&amp;lang=EN" target="_blank">Digital Advertising Alliance</a>, <a href="https://youradchoices.ca/" target="_blank">Digital Advertising Alliance of Canada</a>, <a href="https://www.youronlinechoices.com/" target="_blank">European Interactive Digital Advertising Alliance</a>
</p>
<h3 class="h4">What about other tracking technologies, like web beacons?</h3>
<p>Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has visited our Website or opened an email including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of email marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</p>
<h3 class="h4">Do you use Flash cookies or Local Shared Objects?</h3>
<p>Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other things, collect and store information about your use of our services, fraud prevention, and for other site operations.</p>
<p>If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the Website Storage Settings Panel. You can also control Flash Cookies by going to the Global Storage Settings Panel and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time).</p>
<p>Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content.</p>
<h3 class="h4">Do you serve targeted advertising?</h3>
<p>Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. They can accomplish this by using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details, or other details that directly identify you unless you choose to provide these.</p>
<h3 class="h4">How often will you update this Cookie Policy?</h3>
<p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
<p>The date at the top of this Cookie Policy indicates when it was last updated.</p>
<h3 class="h4">Where can I get further information?</h3>
<p>If you have any questions about our use of cookies or other technologies, please email us at {{ site.settings['email'] }} or by post to:</p>
<p class="mb-0">
{{ site.settings['name'] }}<br>
{{ site.settings['address'] }}
</p>
</div>
<div class="modal-footer">
<button type="button" class="btn btn-primary">Done</button>
</div>
</div>

</template>

<script>
export default {
	name: 'components.gdpr_policy',
	props: {
		site: Object
	},
	emits: [
		'update:tab',
	],
}
</script>

<style>

</style>

<template>
	
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Cookie preferences</h5>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body small">
			<p>We use different types of cookies to optimise your experience on our website. Click on the categories below to learn more about their purposes. You may choose which types of cookies to allow and can change your preferences at any time. Remember that disabling cookies may affect your experience on the website. You can learn more about how we use cookies by visiting our <a href="#"  v-on:click="$emit('update:tab', 'policy')">Cookie Policy</a>.</p>

			<div class="list-group aaio-cookie-preferences">
				
				<label class="list-group-item d-flex p-4" for="essential-cookies">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="essential-cookies" checked="" disabled="">
					</span>
					<span>
						<span class="h6 d-block mb-2">Essential cookies</span>
						<span class="small">These cookies are necessary to the core functionality of our website and some of its features, such as access to secure areas.</span>
					</span>
				</label>
				
										
				<label v-if="site.settings['analytics-google-analytics-id']" class="list-group-item d-flex p-4" for="cookie-consent-google-analytics">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-google-analytics" value="google-analytics">
					</span>
					<span>
						<span class="h6 d-block mb-2">Google Analytics cookies</span>
						<span class="small">These cookies are used to integrate Google Analytics. You can learn more about how Google manages cookies and your data here <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" style="line-break: anywhere;">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-google-ads-id']" class="list-group-item d-flex p-4" for="cookie-consent-google-ads">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-google-ads" value="google-ads">
					</span>
					<span>
						<span class="h6 d-block mb-2">Google Ads cookies</span>
						<span class="small">These cookies are used to integrate Google Ads. You can learn more about how Google manages cookies and your data here <a href="https://policies.google.com/privacy" target="_blank" style="line-break: anywhere;">https://policies.google.com/privacy</a> and here <a href="https://support.google.com/admanager/answer/2839090?hl=en" target="_blank" style="line-break: anywhere;">https://support.google.com/admanager/answer/2839090?hl=en</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-hubspot-id']" class="list-group-item d-flex p-4" for="cookie-consent-hubspot">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-hubspot" value="hubspot">
					</span>
					<span>
						<span class="h6 d-block mb-2">HubSpot cookies</span>
						<span class="small">These cookies are used to integrate HubSpot. You can learn more about how HubSpot manages cookies and your data here <a href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser" target="_blank" style="line-break: anywhere;">https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-linkedin-id']" class="list-group-item d-flex p-4" for="cookie-consent-linkedin">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-linkedin" value="linkedin">
					</span>
					<span>
						<span class="h6 d-block mb-2">LinkedIn cookies</span>
						<span class="small">These cookies are used to integrate LinkedIn. You can learn more about how LinkedIn manages cookies and your data here <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" style="line-break: anywhere;">https://www.linkedin.com/legal/privacy-policy</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-meta-id']" class="list-group-item d-flex p-4" for="cookie-consent-meta">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-meta" value="meta">
					</span>
					<span>
						<span class="h6 d-block mb-2">Meta cookies</span>
						<span class="small">These cookies are used to integrate Meta. You can learn more about how Meta manages cookies and your data here <a href="https://en-gb.facebook.com/privacy/policy/" target="_blank" style="line-break: anywhere;">https://en-gb.facebook.com/privacy/policy/</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-hotjar-id']" class="list-group-item d-flex p-4" for="cookie-consent-hotjar">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-hotjar" value="hotjar">
					</span>
					<span>
						<span class="h6 d-block mb-2">HotJar cookies</span>
						<span class="small">These cookies are used to integrate HotJar. You can learn more about how HotJar manages cookies and your data here <a href="https://www.hotjar.com/privacy/" target="_blank" style="line-break: anywhere;">https://www.hotjar.com/privacy/</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-microsoft-id']" class="list-group-item d-flex p-4" for="cookie-consent-microsoft">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-microsoft" value="microsoft">
					</span>
					<span>
						<span class="h6 d-block mb-2">Microsoft cookies</span>
						<span class="small">These cookies are used to integrate Bing Ads and analytics services. You can learn more about how Microsoft manages cookies and your data here <a href="https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security" target="_blank">https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security</a></span>
						
					</span>
				</label>

														
				<label v-if="site.settings['analytics-clarity-id']" class="list-group-item d-flex p-4" for="cookie-consent-clarity">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-clarity" value="clarity">
					</span>
					<span>
						<span class="h6 d-block mb-2">Microsoft Clarity cookies</span>
						<span class="small">Clarity's normal functioning requires that they set cookies on your browser. These cookies are placed by a setup script we have installed to run Clarity. When the script is installed, Clarity's cookies send nonpersonally identifiable information about you. You can learn more about how Microsoft manages cookies and your data here <a href="https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security" target="_blank">https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security</a></span>
						
					</span>
				</label>

														
				<label class="list-group-item d-flex p-4" for="cookie-consent-other">
					<span class="form-check form-switch me-2">
						<input class="form-check-input" type="checkbox" role="switch" id="cookie-consent-other" value="other">
					</span>
					<span>
						<span class="h6 d-block mb-2">Other cookies</span>
						<span class="small">This is a list of any other cookies that may be used. These are optional and not required for the site to function.</span>
						
					</span>
				</label>
										
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-primary">Done</button>
		</div>
	</div>

</template>

<script>
export default {
	name: 'components.gdpr_preferences',
	props: {
		site: Object
	},
	emits: [
		'update:tab',
	],
}
</script>

<style>

</style>

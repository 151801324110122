import axios from 'axios'

export const client = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
});

client.interceptors.response.use(
	( response ) => {
		return response;
	},
	function ( error ) {
		var response = ( error.response && error.response.data ? error.response.data : error );
		response.status = ( error.response && error.response.status ? error.response.status : 500 )
		return Promise.reject( response );
	}
)

export default {

	async index( workspace_id, payload ) {

		return client.get( '/api/workspace/' + workspace_id + '/site', payload )

	},

	async show( workspace_id, site_id, payload ) {

		return client.get( '/api/workspace/' + workspace_id + '/site/' + site_id, payload )

	},

	async store( workspace_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site', payload )

	},

	async store_wizard( workspace_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/wizard', payload )

	},

	async update( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id, payload )

	},

	async update_wizard( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id + '/wizard', payload )

	},

	async destroy( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id, payload )

	},

	async store_media( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/media', payload )

	},

	async wp_login( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/wp-login', payload )

	},

	async ai_populate( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/ai/populate', payload )

	},

	async ai_sitemap( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/ai/sitemap', payload )

	},

	async ai_design( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/ai/design', payload )

	},

	async init_repo( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/init-repo', payload )

	},

	async git_push( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/git-push', payload )

	},

	async git_pull( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/git-pull', payload )

	},

	async comments( workspace_id, site_id, payload ) {

		return client.get( '/api/workspace/' + workspace_id + '/site/' + site_id + '/comments', payload )

	},

	async add_comment( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/comments', payload )

	},

	async update_comment( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id + '/comments', payload )

	},

	async delete_comment( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/comments/delete', payload )

	},

	async generate_share( workspace_id, site_id, payload ) {

		return client.post( '/api/workspace/' + workspace_id + '/site/' + site_id + '/generate-share', payload )

	},

	async get_board( workspace_id, site_id, payload ) {

		return client.get( '/api/workspace/' + workspace_id + '/site/' + site_id + '/board', payload )

	},

	async update_board_status( workspace_id, site_id, payload ) {

		return client.put( '/api/workspace/' + workspace_id + '/site/' + site_id + '/update-board-status', payload )

	},

}
<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<launch_nav :site="site" :preview="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-50 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">

						
						<form @submit.prevent="submit" class="w-100 mx-auto" style="max-width: 600px;">

							<div class="card">

								<div class="card-body p-4">

									<div class="alert alert-info small">
										<p class="fw-bold mb-2">Make sure you're compliant!</p>
										You should always get advice from a legal expert to make sure your site is GDPR compliant and that the provided policies and built in features accurately reflect your businesses processes.
									</div>

									<div class="list-group mb-3 w-100">
										<label  
										:for="'include-gdpr'" 
										class="list-group-item d-flex align-items-start"
										>
											<span class="me-3">
												<span class="material-icons-outlined">cookie</span>
											</span>
											<span class="me-4 flex-grow-1">
												<p class="fw-bold mb-0">Include GDPR</p>
												<p class="form-text mb-0">If checked we will include a cookie policy, cookie consent modal, let users manage their cookie preferences and block tracking cookies being set until consent has been given.</p>
											</span>
											<div class="form-check form-switch">
												<input 
												:name="'include-gdpr'" 
												class="form-check-input" 
												type="checkbox" 
												role="switch" 
												:id="'include-gdpr'" 
												v-model="site.settings['include-gdpr']"
												>
											</div>
											
										</label>
									</div>

									<btn_submit 
										label="Update GDPR" 
										icon="check"  
										:icon_after="true" 
										:loading="loading" 
									/>	

									<a :href="preview + '/wp-admin/admin.php?page=aaio-dashboard&section=gdpr'" target="_blank" class="mt-4 d-inline-block">View more GDPR options</a>
								</div>
							</div>

						</form>
					</div>	

					<div class="w-50 h-100 position-absolute top-0 end-0 p-4 d-flex align-items-start overflow-auto border-start text-center" style="z-index: 1">
						
						<div class="w-100">
							<div class="btn-group mx-auto">
								<button v-on:click="tab = 'consent'" :class="tab == 'consent' ? 'btn-primary' : 'btn-outline-primary'" class="btn" style="width: 130px;">Consent</button>
								<button v-on:click="tab = 'preferences'" :class="tab == 'preferences' ? 'btn-primary' : 'btn-outline-primary'" class="btn" style="width: 130px;">Preferences</button>
								<button v-on:click="tab = 'policy'" :class="tab == 'policy' ? 'btn-primary' : 'btn-outline-primary'" class="btn" style="width: 130px;">Policy</button>
							</div>

							<div class="w-100 text-start mx-auto" style="max-width: 800px;">
								<div v-if="tab == 'consent'" class="w-100 mx-auto" style="max-width: 800px;">
									<div class="modal d-block position-static mx-auto" tabindex="-1">
										<div class="modal-dialog">
											<gdpr_consent :site="site" v-on:update:tab="(e) => { tab = e }" />
										</div>
									</div>
								</div>

								<div v-if="tab == 'preferences'" class="w-100 mx-auto" style="max-width: 800px;">
									<div class="modal d-block position-static mx-auto" tabindex="-1">
										<div class="modal-dialog">
											<gdpr_preferences :site="site" v-on:update:tab="(e) => { tab = e }" />
										</div>
									</div>
								</div>

								<div v-if="tab == 'policy'" class="w-100">
									<div class="modal d-block position-static mx-auto" tabindex="-1">
										<div class="modal-dialog w-100" style="max-width: 100%">
											<gdpr_policy :site="site" v-on:update:tab="(e) => { tab = e }" />
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>			

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import launch_nav from '@/components/launch_nav'
import btn_submit from '@/components/btn_submit'
import gdpr_consent from '@/components/gdpr_consent'
import gdpr_preferences from '@/components/gdpr_preferences'
import gdpr_policy from '@/components/gdpr_policy'
import site_service from '@/services/site_service'

export default {
	name: 'site.gdpr',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		launch_nav,
		btn_submit,
		gdpr_consent,
		gdpr_preferences,
		gdpr_policy
	},

	data() {
		return {
			loading: false,
			site: null,
			version: 0,
			wp_pages: [],
			tab: 'consent'
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		if ( !('include-gdpr' in this.site.settings) ) {
			this.site.settings['include-gdpr'] = true;
		}
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'gdpr'

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},
	}
}
</script>
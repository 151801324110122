<template>
	
	<div>

		<div class="w-100 d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">

			<div class="d-flex align-items-center justify-content-between">

				<div class="d-flex align-items-center">

					<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
						{{ limitString( site.name, 20 ) }}
					</div>
				
					<div class="btn-group">
						<router-link :to="{ name: 'site.launch' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.launch' ? 'border-primary' : 'border-light'">
							<span class="material-icons-outlined me-2 d-none d-xxl-block">search</span>
							SEO
						</router-link>
						<router-link :to="{ name: 'site.launch.analytics' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.launch.analytics' ? 'border-primary' : 'border-light'">
							<span class="material-icons-outlined me-2 d-none d-xxl-block">analytics</span>
							Analytics
						</router-link>
						<router-link :to="{ name: 'site.launch.gdpr' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.launch.gdpr' ? 'border-primary' : 'border-light'">
							<span class="material-icons-outlined me-2 d-none d-xxl-block">cookie</span>
							GDPR
						</router-link>
						<router-link :to="{ name: 'site.launch.redirects' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.launch.redirects' ? 'border-primary' : 'border-light'">
							<span class="material-icons-outlined me-2 d-none d-xxl-block">sync_alt</span>
							Redirects
						</router-link>
						<router-link :to="{ name: 'site.launch.performance' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.launch.performance' ? 'border-primary' : 'border-light'">
							<span class="material-icons-outlined me-2 d-none d-xxl-block">bolt</span>
							Performance
						</router-link>
						<router-link :to="{ name: 'site.launch.download' }" class="btn p-3 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'site.launch.download' ? 'border-primary' : 'border-light'">
							<span class="material-icons-outlined me-2 d-none d-xxl-block">downloading</span>
							Download
						</router-link>
					</div>

				</div>

			</div>

			<sub_nav :site="site" />

		</div>

	</div>

</template>

<script>
import sub_nav from '@/components/sub_nav'

export default {
	name: 'components.launch_nav',
	props: {
		site: Object,
		modelValue: [String, Number, Object, Boolean]
	},
	components: {
		sub_nav
	},
	computed: {

		value: {
			get() {
				return this.modelValue
			},
			set( value ) {
				this.$emit('update:modelValue', value)			
			}
		},
	},
	methods: {
		limitString(str, maxLength) 
		{
			if (str.length > maxLength) {
				return str.slice(0, maxLength - 3) + '...';
			}
			return str;
		}
	}
}
</script>

<style>

</style>
